import { render, staticRenderFns } from "./index.html?vue&type=template&id=f7f17e0a&"
import script from "./searchStudent.vue?vue&type=script&lang=js&"
export * from "./searchStudent.vue?vue&type=script&lang=js&"
import style0 from "./searchStudent.vue?vue&type=style&index=0&id=f7f17e0a&prod&lang=css&"
import style1 from "./searchStudent.vue?vue&type=style&index=1&id=f7f17e0a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports